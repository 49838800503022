.mask {
  @apply fixed inset-0 bg-black/50;
}
.container{
  @apply w-[65rem] max-h-full;
}
.root {
  @apply fixed inset-0 z-10 flex items-center justify-center;
}
.from {
  @apply flex min-h-full items-center justify-center p-4 text-center h-full;
}
.panel {
  @apply w-full max-h-full rounded-lg transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all flex flex-col;
}
.title {
  @apply text-2xl mxl:text-xl font-medium p-4 flex items-center gap-4;
}
.closeIcon{
  @apply  min-w-[1.8rem] h-[1.8rem] md:min-w-[1.5rem] md:h-[1.5rem] p-0 rounded-full m-0 ml-auto text-default hover:text-black;
}
