.iconColor {
    @apply text-black ml-1.5 cursor-pointer;
}
.label_name {
    @apply capitalize;
}
.dialogBody {
    @apply border-t border-solid border-secondary/20 overflow-auto relative;
}
.dialogText {
    @apply flex gap-2 flex-col text-secondary;
}
.dialogTitle {
    @apply text-base font-primary font-medium;
}
.dialogParagraph {
    @apply text-sm text-gray-500 empty:hidden;
}
.iconWrp{
    @apply inline-flex;
}
.swatchText{}

.dialogDetail{}

.listDatatype > ul {
    @apply ml-[25px] mt-[8px] list-disc;
}
.dialogBody :global(.loading-root){
    @apply top-0 h-full bg-white;
}