.root {
  @apply flex-1 overflow-auto;
}
.container {
  @apply mx-auto flex items-start xl:flex-col xl:items-center md:w-full max-w-site;
  @apply px-6 xl:px-0;
}
.slider {
  @apply inline-block max-w-full flex-1 sticky top-0 w-[calc(100%-500px)] px-5 z-[1];
  @apply xl:static xl:w-full xl:max-w-full xl:basis-full xl:px-0 md:max-w-full;
  /*@apply xl:sticky xl:top-0 xl:bg-white;*/
}
.detail {
  @apply flex max-w-full w-[500px] basis-[500px] px-5 relative z-0 overflow-clip;
  @apply xl:w-full xl:max-w-[500px] xl:basis-full;
}
.viewRoot {
  /* @apply bg-grayLight; */
}
.viewRoot *::selection {
  background-color: transparent;
}
.viewChange {
  @apply absolute top-3 right-3 z-[1] cursor-pointer;
}
.viewChange svg {
  @apply !h-6 !w-6;
}

.iframe {
  @apply pt-[calc(100vh-(var(--stickyHeight)+65px))] min-h-[400px] relative xl:pt-[100%];
}
.viewIframe {
  @apply absolute top-0 left-0 w-full h-full;
}
.inVisible {
  @apply invisible opacity-0 max-h-0 max-w-0 !pt-0 overflow-hidden min-h-0;
}
