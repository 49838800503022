.header {
  @apply w-full bg-white  sticky top-0 z-10;
}
.container {
  @apply min-h-[60px] w-full flex items-center max-w-site px-5 mx-auto justify-between md:gap-1;
  /* @apply md:min-h-[40px]; */
}
.logoWrap {
  @apply cursor-pointer min-h-[inherit];
}
.logoLink {
  @apply min-h-[inherit] max-h-[60px] w-[200px] flex;
}
.helpWrap {
  @apply min-h-[inherit] flex w-full max-w-[200px] justify-end items-center;
}
.helpInner {
  @apply flex justify-end items-center gap-x-5 z-[2];
}
.link {
  @apply flex items-center text-default hover:text-black cursor-pointer;
}
.icon {
  @apply text-inherit mr-1;
}
.logo {
  @apply flex w-full items-center md:w-10;
}
.menuMobile {
  @apply w-[50px] h-[50px] flex items-center justify-center p-3 md:p-1.5 cursor-pointer md:w-[30px] md:h-[30px] sm:p-1;
}
.active {
  position: relative;
}
.linkText{
    @apply flex items-center leading-none font-medium;
}
.tabsWrap {
  @apply flex self-stretch justify-center items-center font-medium relative after:content-[""] after:absolute after:h-[3px] after:w-full after:bg-primary after:bottom-0 after:inset-x-0;
}
.logoLink {
  @apply md:!w-[60px] sm:!w-[40px] xs:!w-[30px];
}
.helpWrap{
  @apply md:!max-w-[60px] sm:!max-w-[40px] xs:!max-w-[30px];
}
.helpWrap :global svg{
  @apply xs:!h-[2em] xs:!w-[2em];
}
@media screen and (max-width: 1079px) {
  .container {
    @apply px-[5px];
  }
  .helpWrap {
    @apply max-w-[80px] relative;
  }
  .logoLink {
    @apply w-[80px] md:w-[60px];
    -webkit-filter: grayscale(1) brightness(0);
    filter: grayscale(1) brightness(0);
  }
  .helpInner {
    @apply flex-col absolute top-[calc(100%)] w-[150px] items-center bg-grayLight ;
  }
  .helpInner:not(.active) {
    @apply hidden;
  }
  .link {
    @apply w-full px-3 py-3;
  }
}


/**/