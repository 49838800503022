.metalColorBody {
    @apply px-2;
}
.metalColorImage {
    @apply flex justify-center p-2.5 w-full;
}
.metalColorLabel, .caratLabel {
    @apply block py-3.5 px-0 text-xs text-black;
}
.metal{
    @apply capitalize
}
.metalColorDetail {
    @apply w-10 h-10 border-white border-2 outline-primary rounded-full cursor-pointer bg-100 outline outline-1 outline-borderDark ;
}
.caratContainer {
    @apply flex gap-3.5;
}
.caratSquare {
    @apply block font-sans text-sm text-gray-700 pt-3 pl-0 min-h-[2.75rem] min-w-[2.75rem] cursor-pointer text-center outline outline-1 outline-borderDark relative inline-flex justify-center overflow-hidden;
}
.swatchesContainer {
    @apply flex gap-3.5;
}
.horizontalLine {
    @apply border-gray-400 w-full my-4
}
.isActive {
    @apply outline outline-2 outline-primary
}
.caratContainer :global .disabled {
    @apply text-borderDark pointer-events-none before:content-[""] before:bg-borderDark before:h-px before:w-[200%] before:absolute before:-translate-x-2/4 before:-translate-y-2/4 before:-rotate-45 before:left-2/4 before:top-2/4;
    @apply  text-gray-500 bg-borderGray relative;
}
