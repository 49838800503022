@import "../LabelRangeSlider/LabelRangeSlider.module.css";

.root {
    @apply ml-auto mb-9 w-[calc(100%-7px)];
}
.root :global .rc-slider-step > .rc-slider-dot:before,
.root :global .rc-slider-step > .rc-slider-dot:after{
    @apply bg-secondary;
}
.root :global .rc-slider-mark-text{
    @apply min-w-[65px];
}
