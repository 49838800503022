:global(.rc-slider-handle) {
  @apply !opacity-100 lg:w-[18px] lg:h-[18px] lg:-mt-[7px];
}
:global(.rc-slider-with-marks *) {
  @apply transition-[0.25s] duration-[ease];
}
:global(.rc-slider-handle-dragging),
:global(.rc-slider-handle:hover),
:global(.rc-slider-handle:focus-visible) {
  @apply !shadow-[0_0_0_2px] !shadow-black/[0.2];
}

/* e9e9e9 */
:global(.rc-slider-step > .rc-slider-dot) {
  @apply bottom-0 h-[4px] w-[12px] bg-grayLight rounded-none;
}
:global(.rc-slider-step > .rc-slider-dot.rc-slider-dot-active) {
}
:global(.rc-slider-step > .rc-slider-dot:before),
:global(.rc-slider-step > .rc-slider-dot:after) {
  content: "";
  @apply h-full w-[35%] absolute bg-[#e9e9e9];
}
:global(.rc-slider-step > .rc-slider-dot.rc-slider-dot-active:before),
:global(.rc-slider-step > .rc-slider-dot.rc-slider-dot-active:after) {
  @apply bg-secondary delay-100;
}
:global(.rc-slider-step > .rc-slider-dot:before) {
  @apply rounded-tr-[50%] rounded-br-[50%] left-0;
}
:global(.rc-slider-step > .rc-slider-dot:after) {
  @apply rounded-tl-[50%] rounded-bl-[50%] right-0;
}
:global(.rc-slider-step > .rc-slider-dot:last-child:after),
:global(.rc-slider-step > .rc-slider-dot:first-child:before) {
  opacity: 0;
}
:global(.rc-slider-mark-text-active){
    color: black !important;
}
:global(.rc-slider-mark-text) {
  @apply top-[5px] max-w-[60px] font-medium;
}
/* :global(.){
    
} */
.root {
  @apply w-[calc(100%-15px)] mx-auto mb-[35px];
}
