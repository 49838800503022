.tab{
    @apply whitespace-nowrap border-b-2 border-transparent py-2 px-1 text-sm font-medium !ml-4 first:!ml-0 flex gap-1 items-center;
    @apply sm:text-xs xs:text-[11px];
}
.tab:not(.active){
    @apply opacity-50;
}
.tab[disabled]:not(.active){
    @apply !opacity-50 border-b-transparent text-black cursor-text;
}
.active{
    @apply text-black border-primary;
}

.menuMain {
    @apply relative text-left flex md:!ml-4 sm:!ml-2.5;
}
.menuMain:first-child .menuItems{
    @apply md:left-full;
}
.menuItems {
    @apply w-[450px] absolute left-0 top-full z-10 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-medium cursor-pointer;
    @apply lg:w-[400px] md:w-[350px] md:left-1/4 lg:left-1/2 sm:left-0 lg:-translate-x-1/2 sm:w-[280px];
}

.dialogDetail {
    @apply flex gap-4 items-center p-6 hover:bg-tableRowHover;
    @apply md:p-3.5 sm:gap-2;
}
.dialogImg {
    @apply w-14 h-14 object-contain;
    @apply sm:w-10 sm:h-10;
}
.dialogText {
    @apply flex gap-1.5 flex-col text-secondary;
}
.dialogTitle {
    @apply text-base xl:text-sm font-primary;
}
.dialogParagraph {
    @apply text-sm xl:text-[0.75rem] sm:text-xs text-gray-500;
}
.dialogBody {
    @apply p-6 border-t border-solid border-secondary/20 cursor-pointer;
}
.detailButton{
    @apply h-14 mx-0 mxl:h-12 mxl:m-0 md:text-xs;
}
.menuWrap{
    /* @apply p-4;  */
}