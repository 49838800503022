.details {
  @apply flex items-center justify-between gap-4 py-2 text-gray-500 font-light text-sm border-b-0 border-solid border-black/10 px-2;
}
.details:nth-child(odd) {
  @apply bg-tableRow;
}
.label{
  @apply capitalize min-w-[120px] text-left pr-1 font-medium;
}
.span{
  @apply capitalize break-all text-right pl-1 font-medium;
}
.spanLink{
  @apply flex items-center text-primary hover:underline;
}
.spanLink svg{
  @apply !h-[1.3em] !w-[1.3em];
}