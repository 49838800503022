.inputRangeSlider {
  @apply relative w-[calc(100%-15px)] mx-auto;
}

.slider_leftValue,
.slider_rightValue {
  @apply relative text-xs mt-4 border border-border border-solid py-0.5 pl-2.5 w-20 bg-white focus-visible:outline-none;
}

.slider_leftValue {
  @apply left-0;
}

.slider_rightValue {
  @apply right-0;
}
.slider_inputs {
  @apply justify-between flex items-center pb-0.5;
  @apply -mx-[5px]
}
.slider_inputs input[type="text"] {
  @apply text-center appearance-none px-[5px] py-1 pt-[0.3rem];
}
.slider_inputs input[type="text"]:hover,
.slider_inputs input[type="text"]:focus {
  @apply border-secondary;
}
.slider_inputs input[type="text"]:focus{
  @apply border-primary;
}

.slider_inputs input[type="text"] {
  -moz-appearance: textfield;
}

.minMaxSame :global .rc-slider-track{
  width: 100% ;
}
.minMaxSame :global .rc-slider-handle-2 {
  left: 100% ;
}
