.wrapper{
    @apply flex-1 flex flex-col overflow-auto;
    @apply md:overflow-hidden;
}
.tabs{
    @apply !relative flex-1 flex flex-col overflow-visible;
    @apply md:max-h-full md:overflow-hidden;
    /*@apply pt-[60px] relative;*/
}
.center{
    @apply w-full flex items-center justify-center text-[1.2rem] italic;
}
:global(.filterIsActive) .wrapper{
    @apply md:overflow-hidden;
}