/* .items {
    @apply flex-1 w-1/3 lg:w-1/2 md:w-full basis-1/3 lg:basis-1/2 md:basis-full px-5;
} */
.items {
    /* @apply flex-1 w-1/4 lg:w-1/4 md:w-full basis-1/4 px-5; */
    @apply flex-1 w-1/3 lg:w-1/2 md:w-full basis-1/3 px-5 2xl:px-3.5;
    /*@apply max-w-[33.333333%] xxxl:max-w-[33.333333%] xxxl:basis-1/2 lg:max-w-[50%] lg:basis-full md:max-w-full md:basis-0 md:flex-none;*/
    @apply max-w-[25%] 4xl:max-w-[33.333333%] xxxl:basis-1/2 mxl:max-w-[50%] lg:basis-full md:max-w-full md:basis-0 md:flex-none;
    @apply first:ml-auto last:mr-auto;
}
.swatchImg {
    @apply h-4 w-4;
}
.wrapper{
    @apply flex flex-wrap w-full gap-2;
}
.button {
    @apply flex-row gap-[0.475rem];
}
