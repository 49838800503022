.items {
    @apply flex-1 w-1/4 lg:w-1/4 md:w-full basis-1/4 px-5;
    @apply max-w-[25%] xxxl:max-w-[33.333333%] xxxl:basis-1/2 lg:max-w-[50%] lg:basis-full md:max-w-full md:basis-0 md:flex-none;
}
.items :global(.labelRoot button){
    @apply my-0;
}
/* .button{
    padding-top: 0.6rem;
} */
.button span{
    padding-top: 0;
}
.checkmark{
    @apply inline-flex align-middle ml-auto mr-1 gap-2 items-center leading-[normal] ;
}
