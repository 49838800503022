.root {
    @apply flex flex-wrap gap-y-4 xl:gap-y-2;
}
.label_wrap{
    @apply w-full text-left flex items-center font-medium ;
 }
 .label_wrap em{
    @apply text-default ml-1.5;
 }
.root :global(button) {
    @apply flex-1 font-medium;
}
.iconColor {
    @apply text-primary ml-1.5 cursor-pointer;
}
.label_name {
    @apply capitalize;
}
.dialogBody {
    @apply p-6 border-t border-solid border-secondary/20;
}
.dialogText {
    @apply flex gap-2 flex-col text-secondary;
}
.dialogTitle {
    @apply text-base font-primary font-medium;
}
.dialogParagraph {
    @apply text-sm text-gray-500;
}
.iconWrp{
    @apply inline-flex;
}
.swatchText{}